import React from "react"
import {
    FaFacebookSquare,
    FaInstagram,
} from "react-icons/fa"

const data = [
    {
        id: 1,
        icon: <FaFacebookSquare className="social-icon" size={42}></FaFacebookSquare>,
        url: "https://www.facebook.com/maivitaoy",
    },
    {
        id: 2,
        icon: <FaInstagram className="social-icon" size={42}></FaInstagram>,
        url: "https://www.instagram.com/maivita_paihdehoitokeskus/?hl=fi",
    }
]

export default data