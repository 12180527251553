/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useState} from "react"
import PropTypes from "prop-types"
import "../../assets/fonts/fonts.css"
import {GlobalStyles} from "../../styles/GlobalStyles"
import "leaflet/dist/leaflet.css"
import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import WhatsApp from "../WhatsApp";
import CookieConsent from "../CookieConsent";

const Layout = ({children}) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleSidebar = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <GlobalStyles/>

            <Navbar toggleSidebar={toggleSidebar}/>
            <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar}/>

            <img src="https://secure.adnxs.com/seg?member_id=7458&add_code=616079A&t=2" width="1" height="1" style={{display: 'none'}} alt="none" />

            <WhatsApp phoneNumber='358400315069' message='Hei, olen kiinnostunut palveluistanne'/>

            <div>
                <main>{children}</main>

                <CookieConsent />
                <Footer/>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
