import React from "react"
import socialLinks from "../../constants/social_links"
import {Link} from "gatsby"
import {FaHome, FaTimes} from "react-icons/fa"
import styled from "styled-components"
import pageLinks from "../../constants/links";

const SidebarListItem = styled.li`
  list-style: none;
  margin-left: 0;
  margin-bottom: 20px;  

  .main-menu {
    text-align: center;
    color: var(--clr-grey-10);
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 1.2em;
  }
`

const Sidebar = ({isOpen, toggleSidebar}) => {

    return <aside className={isOpen ? "sidebar show-sidebar" : "sidebar"}>
        <button type="button" className="close-btn" onClick={toggleSidebar}>
            <FaTimes/>
        </button>
        <div className="side-container">
            <ul className={isOpen ? "sidebar-links" : null}>

                {pageLinks.map((link, index) => {

                    return (
                        !link.subMenuItems ? (

                            <SidebarListItem key={index}>
                                <Link key={link.id} to={link.url} onClick={toggleSidebar}>
                                    {link.text === "koti" ? <FaHome size={28}/> : link.text}
                                </Link>
                            </SidebarListItem>

                        ) : (
                            <SidebarListItem key={index}>
                                <div className="main-menu">{link.text}</div>
                                {<div>
                                    {link.subMenuItems?.map((subMenuItem) => (

                                        subMenuItem.url !== '' && (

                                            <div key={subMenuItem.id}>

                                                <Link to={`${subMenuItem.url}`} onClick={toggleSidebar}>
                                                    {subMenuItem.text}
                                                </Link>

                                            </div>
                                        )
                                    ))}
                                </div>}
                            </SidebarListItem>
                        )
                    )

                })}
            </ul>
            {<ul className={isOpen ? "social-links sidebar-icons" : null}>
                {socialLinks.map(link => {
                    return (
                        <SidebarListItem key={link.id}>
                            <a href={link.url} className="social-link">
                                {link.icon}
                            </a>
                        </SidebarListItem>
                    )
                })}
            </ul>}
        </div>
    </aside>
}

export default Sidebar
