exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-templates-aboutpage-js": () => import("./../../../src/templates/aboutpage.js" /* webpackChunkName: "component---src-templates-aboutpage-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-contactpage-js": () => import("./../../../src/templates/contactpage.js" /* webpackChunkName: "component---src-templates-contactpage-js" */),
  "component---src-templates-gallerypage-js": () => import("./../../../src/templates/gallerypage.js" /* webpackChunkName: "component---src-templates-gallerypage-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

