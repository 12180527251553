import * as React from 'react'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import {CookieButtons, CookieConsentModal, CookieConsentText, CookieConsentWrapper, CookieLink, Logo} from "./styles"
import logo from "../../assets/images/Maivita_logo.jpg"
import {Link} from "gatsby"

function isBrowser() {
    return typeof window !== 'undefined';
}

function getValue(key, defaultValue) {
    return isBrowser() && window.localStorage.getItem(key)
        ? JSON.parse(window.localStorage.getItem(key))
        : false;
}

function setValue(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
}

function useStickyState(defaultValue, key) {
    const [value, setter] = React.useState(() => {
        return getValue(key, defaultValue);
    });

    React.useEffect(() => {
        setValue(key, value);
    }, [key, value]);

    return [value, setter];
}

const checkIfCookieExists = () => {
    let hasConsent = false;
    if (isBrowser()) {
        hasConsent = document.cookie.split(';').some((item) => item.trim().startsWith('gatsby-gdpr-google-tagmanager='))
    }
    return hasConsent
}

const CookieConsent = () => {
    const location = useLocation();
    if (isBrowser()) {
        initializeAndTrack(location);
    }

    const [bannerHidden, setBannerHidden] = useStickyState(
        true,
        'consentCookieHidden',
    );

    const EnableAnalytics = (isEnabled) => {
        document.cookie = 'gatsby-gdpr-google-tagmanager=' + isEnabled;
        setBannerHidden(true);
    };

    return (
        <>
            {!bannerHidden && (
                <CookieConsentWrapper>
                    <CookieConsentModal>
                        <Logo src={logo} alt="maivita"/>
                        <CookieConsentText>
                            Käytämme sivustollamme evästeitä tarjotaksemme sinulle parhaan mahdollisen käyttökokemuksen.
                            <CookieLink>
                                <Link to="" onClick={() => EnableAnalytics('false')}>Jatka vain välttämättömillä evästeillä</Link>
                            </CookieLink>
                        </CookieConsentText>
                        <CookieButtons>
                            <CookieLink>
                                <a href='/Tietosuojaseloste_Maivita.pdf' target="_blank" rel="noreferrer">
                                    Tietosuojaseloste
                                </a>
                            </CookieLink>
                            <button className="ml-3 btn-maivita" onClick={() => EnableAnalytics('true')}>Salli evästeet</button>
                        </CookieButtons>
                    </CookieConsentModal>
                </CookieConsentWrapper>
            )}
        </>
    );
};

export default CookieConsent;
