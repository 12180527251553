import React from "react";
import {FaWhatsapp} from "react-icons/fa";
import {WhatsAppWrapper} from "./styles";

const WhatsApp = ({phoneNumber, message}) => {
    return (
        <WhatsAppWrapper
            href={`https://wa.me/${phoneNumber}?text=${message}`}
            target="_blank"
            rel="noopener noreferrer">
            <FaWhatsapp className="whatsapp-icon" size={40}/>
        </WhatsAppWrapper>
    )
}

export default WhatsApp