import styled from "styled-components";

export const CookieConsentWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 200;
  background-color: #626b77;
  background-color: rgba(0,0,0,0.6);
  opacity: 0;
  animation: showModal 1.5s ease-in-out .5s forwards;
`

export const CookieConsentModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.4rem;
  background-color: white;
  color: black;
  border-radius: 6px;
  max-width: 600px;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);

  @media screen and (max-width: 768px) {
    left: 0;
    margin: 6px;
    font-size: 1.1rem;
    
    a {
      font-size: 0.8rem;
    }

    transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
  }

  @keyframes showModal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const CookieConsentText = styled.div`
  background-color: #f9f9f9;
  padding: 30px;
`

export const CookieLink = styled.div`
    a {
      color: var(--clr-maivita-dark-green);
      font-size: 1rem;
    }
`

export const CookieButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  padding: 10px;

  @media screen and (max-width: 768px) {
    margin: 2rem 1rem 1rem 0;
  }
`

export const Logo = styled.img`
  width: 200px;
  margin: 1rem;
`
