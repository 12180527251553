import styled from "styled-components"

export const WhatsAppWrapper = styled.a`

  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 30px;
  right: 30px;
  color: #25d366;
  background-color: #25d366;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  z-index: 999;

  .whatsapp-icon {
    color: white;
    margin-top: 10px;
  }
`
