import React from "react"
import logo from "../../assets/images/Maivita_logo.jpg"
import {FaAlignRight, FaHome} from "react-icons/fa"
import pageLinks from "../../constants/links"
import {Link} from "gatsby"
import styled from "styled-components"

const Logo = styled.img`
  width: 200px;
  margin-top: 0.375rem;

  @media screen and (max-width: 1170px) {
    width: 120px;
  }
`

const NavWrapper = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  z-index: 200;
  background: var(--clr-white);

  @media screen and (min-width: 992px) {
    background: transparent;
  }
`

const NavCenter = styled.div`
  width: 98vw;
  padding: 12px;
  max-width: var(--max-width);
  margin: 0 auto;

  @media screen and (min-width: 991px) {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
`
const NavHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NavLinks = styled.div`
  display: none;
  
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: flex-end;

    a, .main-menu {
      margin-right: 1rem;
      color: var(--clr-grey-1);
      font-weight: 600;
      letter-spacing: var(--spacing-nav);
      transition: var(--transition);
    }

    a:hover {
      color: var(--clr-maivita-green);
      box-shadow: 0px 2px var(--clr-maivita-green);
    }
  }

  @media screen and (min-width: 1169px) {
    a, .main-menu {
      letter-spacing: var(--spacing);
    }
  }

  @media screen and (min-width: 1024px) {
    a, .main-menu {
      margin-right: 2rem;
      letter-spacing: var(--spacing);
    }
  }
`

const SubMenuItemWrapper = styled.div`
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 0;

  &:hover {
    > div:last-child {
      display: block;
      left: -40px;

      @media screen and (max-width: 992px) {
        left: -90px;
      }
    }
  }

  > div:last-child {
    display: none;
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 0px;
    background: white;
    white-space: nowrap;

    padding: 8px 16px;
    border: 1px solid #ccc;

    > div {
      line-height: 1;
      padding: 8px 0px 8px 8px;
      margin-bottom: 6px;
    }

    @media screen and (max-width: 1169px) {
      padding: 8px 0 8px 8px;
    }
  }
`

const ToggleButton = styled.button`
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: var(--clr-maivita-green);
  cursor: pointer;
  transition: var(--transition);

  &:hover {
    color: var(--clr-primary-2);
  }

  @media screen and (min-width: 991px) {
    display: none;
  }
`

const Divider = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 0;
`

const Navbar = ({toggleSidebar}) => {
    return <NavWrapper>
        <NavCenter>
            <NavHeader>
                <Link to="/">
                    <Logo src={logo} alt="maivita"/>
                </Link>
                <ToggleButton onClick={toggleSidebar}>
                    <FaAlignRight/>
                </ToggleButton>
            </NavHeader>
            <NavLinks>

                {pageLinks.map((link, index) => {

                    return (
                        !link.subMenuItems ? (

                            <Link key={link.id} to={link.url}>
                                {link.text === "koti" ? <FaHome id="home-icon" size={28}/> : link.text}
                            </Link>

                        ) : (
                            <SubMenuItemWrapper key={link.id}>
                                <div className="main-menu">{link.text}</div>
                                <div>
                                    {link.subMenuItems?.map((subMenuItem) => (
                                        <div key={subMenuItem.id}>

                                            {subMenuItem.text === '-' ? <Divider/> :
                                                <Link to={`${subMenuItem.url}`}>
                                                    {subMenuItem.text}
                                                </Link>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </SubMenuItemWrapper>
                        )
                    )

                })}
            </NavLinks>
        </NavCenter>
    </NavWrapper>
}


export default Navbar